$theme_option_item_height: 60px;

// Colors options
// for logo (there're 2, one for sidebar-header, one for header logo, here only for sidebar-header), top header, sidebar
.color-option-check {
  position: relative;
  display: block;
  input[type="radio"] {
    display: none;
  }
  input[type="radio"] + span:hover {
    cursor: pointer;
  }
  input[type="radio"] + span {
    position: relative;
    > .overlay {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      text-align: center;
      line-height: 30px;
      color: #fff;
    }
  }
  input[type="radio"]:checked + span {
    > .overlay {
      display: block;
    }
  }

  //
  .color-option-item {
    overflow: hidden;
    display: block;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    width: 100%;

    > span {
      display: block;
      float: left;
      width: 50%;
      height: 20px;
      overflow: hidden;
    }

    .item-header {
      height: 10px;
    }
  }
}

// to make it more distinguishable from white color
.color-option-check {
  .bg-color-page {
    background-color: #f1f1f1;
  }
}

// Themes options
.theme-options {
  > div {
    padding: 0;
  }
}
.theme-option-check {
  position: relative;
  display: block;
  margin: 0;
  font-weight: normal;
  input[type="radio"] {
    display: none;
  }
  input[type="radio"] + span:hover {
    cursor: pointer;
  }
  input[type="radio"] + span {
    position: relative;
    > .overlay {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background-color: rgba(0,0,0,.2);
      text-align: center;
      line-height: $theme_option_item_height;
      color: #fff;
      .material-icons {
        vertical-align: bottom;
        color: theme-color("success");
      }
    }
  }
  input[type="radio"]:checked + span {
    > .overlay {
      display: block;
    }
  }

  //
  .theme-option-item {
    overflow: hidden;
    display: block;

    > span {
      display: block;
      text-align: center;
      height: $theme_option_item_height;
      line-height: $theme_option_item_height;
      text-transform: uppercase;
    }
  }
}

.react-datepicker__input-container {
  input {
    width: 200px;
    min-width: 200px;
    margin-bottom: 2px;
  }
}

.checkboxRequestForm {
  margin-left: -12px !important;
}

.reportDatePicker {
  margin-left: 1px;
  margin-bottom: 10px;
}

.timeInformationInput {
  @media screen and (max-width: 768px) {
    border-bottom: 2px solid lightgray;
    padding-bottom: 15px;
  }
}

.muiDropdownTimeRequest {
  min-width: 200px !important;
  max-width: 200px !important;
}

label {
  margin-top: 0.5rem !important;
}

@media screen and (max-width: 576px) {
  .container-fluid {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  // always place the timepicker at the bottom
  .react-datepicker-popper {
    transform: translate3d(0px, 40px, 0px) !important;
  }
  // hide the triangle
  .react-datepicker__triangle {
    display:none !important
  }
  // float the time container left
  .react-datepicker__time-container {
    float: left !important;
  }
}
