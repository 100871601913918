@font-face {
    font-family: 'UtilityPro';
    src: url('../../assets/fonts/UtilityPro-Light.eot');
    src: url('../../assets/fonts/UtilityPro-Light.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/UtilityPro-Light.woff2') format('woff2'),
        url('../../assets/fonts/UtilityPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'UtilityPro';
    src: url('../../assets/fonts/UtilityPro-Medium.eot');
    src: url('../../assets/fonts/UtilityPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/UtilityPro-Medium.woff2') format('woff2'),
        url('../../assets/fonts/UtilityPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'UtilityPro';
    src: url('../../assets/fonts/UtilityPro.eot');
    src: url('../../assets/fonts/UtilityPro.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/UtilityPro.woff2') format('woff2'),
        url('../../assets/fonts/UtilityPro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'UtilityPro';
    src: url('../../assets/fonts/UtilityPro-Black.eot');
    src: url('../../assets/fonts/UtilityPro-Black.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/UtilityPro-Black.woff2') format('woff2'),
        url('../../assets/fonts/UtilityPro-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'UtilityPro';
    src: url('../../assets/fonts/UtilityPro-Bold.eot');
    src: url('../../assets/fonts/UtilityPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/UtilityPro-Bold.woff2') format('woff2'),
        url('../../assets/fonts/UtilityPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
